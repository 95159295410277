import React from 'react';
import {
  Box,
  Typography,
  Card,
  useTheme,
  CircularProgress,
  Chip,
  Link,
} from '@mui/material';
import { ExternalLink, ThumbsUp, ThumbsDown } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import ProductSwiper from './ProductSwiper';

const LoadingSpinner = () => (
  <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
    <CircularProgress size={40} />
  </Box>
);

const ProductCard = ({ product }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: 2,
        gap: 2,
        my: 2,
        borderRadius: '12px',
        border: `1px solid ${theme.palette.divider}`,
        transition: 'all 0.2s',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        },
      }}
    >
      <Box
        component="img"
        src={product.image}
        alt={product.name}
        sx={{
          width: 80,
          height: 80,
          objectFit: 'contain',
          borderRadius: '8px',
        }}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
          {product.name}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h6" color="primary">
            {product.price}
          </Typography>
          {product.rating && (
            <Chip
              label={`★ ${product.rating}`}
              size="small"
              sx={{
                bgcolor: theme.palette.gold.light,
                color: theme.palette.secondary.contrastText,
              }}
            />
          )}
        </Box>
      </Box>
      <Link
        href={product.link}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          color: theme.palette.primary.main,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        View on {product.seller} <ExternalLink size={16} />
      </Link>
    </Card>
  );
};

const AmazonSuggestionCard = ({ productName }) => {
  const theme = useTheme();
  const searchQuery = encodeURIComponent(productName.replace(/[{}]/g, ''));
  const amazonLink = `https://www.amazon.com/s?k=${searchQuery}&linkCode=ll2&tag=buywithai02c-20&linkId=cee736e588c5a342c48f64764d70d4c0&language=en_US&ref_=as_li_ss_tl`;

  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: 2,
        my: 2,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '12px',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: `0 4px 12px ${theme.palette.primary.main}20`,
        },
      }}
    >
      <Box
        component="img"
        src="/images/amazon-logo.png"
        alt="Amazon"
        sx={{
          height: 30,
          width: 'auto',
          mr: 2,
        }}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 500,
          }}
        >
          Find "{productName}" on Amazon
        </Typography>
      </Box>
      <Link
        href={amazonLink}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          color: theme.palette.primary.main,
          '&:hover': {
            color: theme.palette.primary.dark,
          },
        }}
      >
        <ExternalLink size={20} />
      </Link>
    </Card>
  );
};

const ResponseSection = ({
  displayedAnswer,
  selectedInputValue,
  isTyping,
  isLoading,
  productData,
  selectedItems,
  responseSectionRef,
}) => {
  const theme = useTheme();

  const renderMarkdownWithProducts = (markdown) => {
    const lines = markdown.split("\n");

    return lines.map((line, index) => {
      const regex = /{{(.*?)}}/g;
      const matches = line.match(regex);

      if (matches) {
        const productName = matches[0].replace(/[{}]/g, '');
        const reformattedLine = line.replace(regex, `**${productName}**`);

        // Try to find the product in productData
        const matchingProduct = productData?.find(p =>
          p.name.toLowerCase().includes(productName.toLowerCase())
        );

        return (
          <React.Fragment key={index}>
            <ReactMarkdown>{reformattedLine}</ReactMarkdown>
            {matchingProduct ? (
              <ProductCard product={matchingProduct} />
            ) : (
              <AmazonSuggestionCard productName={productName} />
            )}
          </React.Fragment>
        );
      }

      return <ReactMarkdown key={index}>{line}</ReactMarkdown>;
    });
  };

  return (
    <Box ref={responseSectionRef}>
      {(isLoading || displayedAnswer) && (
        <>
          <Box width={{ xs: "100%", md: "50%", lg: "30%" }}>
            <Card
              sx={{
                fontSize: { xs: "0.9rem", sm: "1rem" },
                fontWeight: "bold",
                backgroundColor: theme.palette.background.paper,
                borderRadius: "16px",
                p: 2,
                m: 1,
              }}
            >
              <Typography variant="body2">{selectedInputValue}</Typography>
            </Card>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Card
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: "16px",
                p: { xs: 2, sm: 3 },
                minHeight: "200px",
                boxShadow: '0 8px 32px rgba(45, 50, 80, 0.12)',
              }}
            >
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="primary"
                    sx={{ mb: 2 }}
                  >
                    Here's what I found for you:
                  </Typography>
                  <Typography component="div">
                    {renderMarkdownWithProducts(displayedAnswer)}
                    {isTyping && (
                      <span style={{
                        animation: "blink 0.7s infinite",
                        opacity: 0.5
                      }}>
                        ▊
                      </span>
                    )}
                  </Typography>

                  {/* Feedback Section */}
                  {!isTyping && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 4,
                        pt: 3,
                        borderTop: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        Was this response helpful?
                      </Typography>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Chip
                          icon={<ThumbsUp size={16} />}
                          label="Yes"
                          clickable
                          sx={{
                            '&:hover': { bgcolor: theme.palette.success.light }
                          }}
                        />
                        <Chip
                          icon={<ThumbsDown size={16} />}
                          label="No"
                          clickable
                          sx={{
                            '&:hover': { bgcolor: theme.palette.error.light }
                          }}
                        />
                      </Box>
                    </Box>
                  )}

                  {/* Related Products Section */}
                  {!isTyping && productData?.length > 0 && (
                    <Box sx={{ mt: 4 }}>
                      <Typography variant="h6" gutterBottom>
                        More Related Products
                      </Typography>
                      <ProductSwiper productData={productData} />
                    </Box>
                  )}
                </>
              )}
            </Card>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ResponseSection;
