import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { logUserQuery, logQueryResponse, logQueryFeedback } from '../graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import { Amplify } from 'aws-amplify';

// Configure Amplify with API key
Amplify.configure({
  API: {
    GraphQL: {
      endpoint: process.env.REACT_APP_APPSYNC_API_URL,
      region: process.env.REACT_APP_AWS_REGION,
      defaultAuthMode: 'apiKey',
      apiKey: process.env.REACT_APP_API_KEY,
    }
  }
});

const client = generateClient();

export const useQueryLog = () => {
  const [loading, setLoading] = useState(false);
  const [loggingError, setLoggingError] = useState(null);
  const [currentQueryId, setCurrentQueryId] = useState(null);

  // Step 1: Log the initial user query
  const logQuery = async (queryText) => {
    setLoading(true);
    setLoggingError(null);
    try {
      const browserId = localStorage.getItem('browserId') || uuidv4();
      const sessionId = sessionStorage.getItem('sessionId') || uuidv4();

      if (!localStorage.getItem('browserId')) {
        localStorage.setItem('browserId', browserId);
      }
      if (!sessionStorage.getItem('sessionId')) {
        sessionStorage.setItem('sessionId', sessionId);
      }

      const result = await client.graphql({
        query: logUserQuery,
        variables: {
          input: {
            queryText,
            response: "", // Provide empty string for required field
            browserId,
            sessionId,
            userId: "anonymous", // Provide default value
            ds: new Date().toISOString(),
            unixTime: Math.floor(Date.now() / 1000),
            browserInfo: JSON.stringify(navigator.userAgent),
            queryType: "USER_QUERY"
          }
        }
      });

      const queryId = result.data.createQueryLog.id;
      setCurrentQueryId(queryId);
      return queryId;

    } catch (err) {
      setLoggingError(err);
      console.error('Error logging user query:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Step 2: Log the API response
  const logResponse = async (queryId, response, responseTimeMs) => {
    setLoading(true);
    try {
      const result = await client.graphql({
        query: logQueryResponse,
        variables: {
          input: {
            id: queryId,
            response,
            responseTimeMs,
            queryType: "API_RESPONSE"
          }
        }
      });
      return result.data.updateQueryLog;
    } catch (err) {
      console.error('Error logging response:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Step 3: Log user feedback
  const logFeedback = async (queryId, feedback) => {
    setLoading(true);
    try {
      const result = await client.graphql({
        query: logQueryFeedback,
        variables: {
          input: {
            id: queryId,
            feedback,
            queryType: "USER_FEEDBACK"
          }
        }
      });
      return result.data.updateQueryLog;
    } catch (err) {
      console.error('Error logging feedback:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    logQuery,
    logResponse,
    logFeedback,
    currentQueryId,
    loading,
    loggingError,
  };
};
