import React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const CardItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  minHeight: "120px",
  width: "200px",
  height: "100px",
  elevation: 10,
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  position: 'relative',
  '& img': {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    width: '24px',
    height: '24px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    minHeight: '80px',
    width: '150px',
    height: '80px',
  },
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: theme.palette.secondary.light,
    cursor: "pointer",
    elevation: 20,
  },
  "&:active": {
    transform: "scale(1.05)",
    backgroundColor: theme.palette.secondary.light,
  },
}));

export default CardItem;

// import React from "react";
// import Paper from "@mui/material/Paper";
// import { styled } from "@mui/material/styles";
// // import logo from 'path/to/logo.png'; // Make sure to replace 'path/to/logo.png' with the actual path to the logo file

// const CardItem = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.background.primary,
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "left",
//   minHeight: "120px",
//   width: "200px",
//   height: "100px",
//   elevation: 10,
//   color: theme.palette.text.secondary,
//   fontFamily: 'Calibri, sans-serif',
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'flex-end',
//   position: 'relative',
//   '& img': {
//     position: 'absolute',
//     top: theme.spacing(1),
//     left: theme.spacing(1),
//     width: '24px',
//     height: '24px',
//   },
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '0.8rem',
//     minHeight: '80px',
//     width: '150px',
//     height: '80px',
//   },
//   "&:hover": {
//     transform: "scale(1.05)",
//     boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
//     backgroundColor: "#e0e0e0",
//     cursor: "pointer",
//     elevation: 20,
//   },
//   "&:active": {
//     transform: "scale(1.05)",
//     backgroundColor: "#e0e0e0",
//   },
// }));
// export default CardItem;

// const CardItemComponent = ({ children }) => (
//   <CardItem>
//     <img src={logo} alt="logo" />
//     {children}
//   </CardItem>
// );

// export default CardItemComponent;