import React, { useState } from 'react';
import { Container, TextField, Button, Box } from '@mui/material';
import TemplateFrame from './TemplateFrame';

const MainPage = () => {
  const [input, setInput] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/text-response', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: input }),
      });
      const data = await response.json();
      // Handle the response data
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <TemplateFrame>
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Enter your text here"
          />
          <Button type="submit" variant="contained" sx={{ mt: 2 }}>
            Send
          </Button>
        </Box>
      </Container>
    </TemplateFrame>
  );
};

export default MainPage;