// src/components/CookieConsent.js
import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Typography, 
  Dialog, 
  DialogContent, 
  DialogActions 
} from '@mui/material';
import Cookies from 'js-cookie';

const CookieConsent = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const hasConsent = Cookies.get('cookie_consent');
    if (!hasConsent) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('cookie_consent', 'true', { expires: 365 });
    setOpen(false);
  };

  const handleDecline = () => {
    Cookies.set('cookie_consent', 'false', { expires: 365 });
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '12px',
        },
      }}
    >
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Cookie Preferences
        </Typography>
        <Typography variant="body2" color="text.secondary">
          We use cookies to improve your experience and analyze our site usage. 
          These help us understand how you use our site and provide personalized service.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2, gap: 1 }}>
        <Button 
          onClick={handleDecline}
          variant="outlined"
          size="large"
        >
          Decline
        </Button>
        <Button 
          onClick={handleAccept}
          variant="contained"
          size="large"
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookieConsent;