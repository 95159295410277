import React, { useState } from "react";
import { 
  Box, 
  Container, 
  Typography, 
  Card, 
  IconButton, 
  useTheme,
  Fade,
  LinearProgress
} from "@mui/material";
import { ChevronLeft, ChevronRight, Lightbulb } from "lucide-react";

const QuestionCard = ({ question, onClick, isLoading }) => {
  const theme = useTheme();
  
  return (
    <Card
      onClick={onClick}
      sx={{
        p: 3,
        cursor: 'pointer',
        transition: 'all 0.2s',
        position: 'relative',
        minHeight: '120px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '&:hover': {
          transform: 'translateY(-4px)',
          bgcolor: 'background.highlight',
          '& .hover-icon': {
            opacity: 1,
            transform: 'translateX(0)',
          }
        }
      }}
    >
      <Typography 
        variant="body1" 
        fontWeight="bold"
        sx={{ 
          mb: 2,
          color: isLoading ? 'text.secondary' : 'text.primary',
        }}
      >
        {question}
      </Typography>
      
      {/* Hover icon */}
      <Box 
        className="hover-icon"
        sx={{ 
          opacity: 0,
          transform: 'translateX(-10px)',
          transition: 'all 0.2s',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          color: theme.palette.primary.main,
        }}
      >
        <Lightbulb size={16} />
        <Typography variant="caption" fontWeight={500}>
          Ask this question
        </Typography>
      </Box>
      
      {isLoading && (
        <LinearProgress 
          sx={{ 
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
          }}
        />
      )}
    </Card>
  );
};

const PopularQuestionsSection = ({ cardData, onQuestionClick, loadingQuestion }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const questionsPerPage = 3;
  const totalPages = Math.ceil(cardData.length / questionsPerPage);
  const theme = useTheme();

  const handlePageChange = (direction) => {
    setIsTransitioning(true);
    setTimeout(() => {
      if (direction === 'next') {
        setCurrentPage((prev) => (prev + 1) % totalPages);
      } else {
        setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
      }
      setIsTransitioning(false);
    }, 200);
  };

  const currentQuestions = cardData.slice(
    currentPage * questionsPerPage,
    (currentPage + 1) * questionsPerPage
  );

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 6, md: 8 } }}>
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 4,
        }}
      >
        <Typography variant="h2">
          Popular Questions
        </Typography>
        
        {/* Navigation controls */}
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton 
            onClick={() => handlePageChange('prev')}
            disabled={totalPages <= 1}
            sx={{
              bgcolor: 'background.paper',
              boxShadow: `0 2px 8px ${theme.palette.primary.main}20`,
              '&:hover': { 
                bgcolor: 'background.paper',
                boxShadow: `0 4px 12px ${theme.palette.primary.main}30`,
              },
            }}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton 
            onClick={() => handlePageChange('next')}
            disabled={totalPages <= 1}
            sx={{
              bgcolor: 'background.paper',
              boxShadow: `0 2px 8px ${theme.palette.primary.main}20`,
              '&:hover': { 
                bgcolor: 'background.paper',
                boxShadow: `0 4px 12px ${theme.palette.primary.main}30`,
              },
            }}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>

      <Fade in={!isTransitioning} timeout={200}>
        <Box 
          sx={{ 
            display: 'grid',
            gridTemplateColumns: { 
              xs: '1fr', 
              md: 'repeat(3, 1fr)' 
            },
            gap: 3,
          }}
        >
          {currentQuestions.map((card) => (
            <QuestionCard 
              key={card.id}
              question={card.text}
              onClick={() => onQuestionClick(card)}
              isLoading={loadingQuestion === card.text}
            />
          ))}
        </Box>
      </Fade>

      {/* Page indicator */}
      {totalPages > 1 && (
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            gap: 1,
            mt: 4 
          }}
        >
          {[...Array(totalPages)].map((_, index) => (
            <Box
              key={index}
              sx={{
                width: 24,
                height: 4,
                borderRadius: 2,
                bgcolor: index === currentPage ? 'primary.main' : 'primary.light',
                opacity: index === currentPage ? 1 : 0.3,
                transition: 'all 0.2s',
              }}
            />
          ))}
        </Box>
      )}
    </Container>
  );
};

export default PopularQuestionsSection;