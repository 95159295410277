import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signUp, confirmSignUp } from 'aws-amplify/auth';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Alert,
  useTheme,
} from '@mui/material';
import { Mail, Lock, Eye, EyeOff, User, ArrowLeft } from 'lucide-react';

const SignUp = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = 'Name is required';
    }
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email';
    }
    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    setErrorMessage('');

    try {
      const { nextStep } = await signUp({
        username: formData.email,
        password: formData.password,
        options: {
          userAttributes: {
            email: formData.email,
            name: formData.name,
          },
        },
      });

      if (nextStep.signUpStep === 'CONFIRM_SIGN_UP') {
        setShowConfirmation(true);
      }
    } catch (error) {
      setErrorMessage(
        error.message || 'An error occurred during sign up. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmation = async () => {
    try {
      const { isSignUpComplete } = await confirmSignUp({
        username: formData.email,
        confirmationCode,
      });

      if (isSignUpComplete) {
        navigate('/signin');
      }
    } catch (error) {
      setErrorMessage(
        'Invalid confirmation code. Please try again.'
      );
    }
  };

  return (
    <Box 
      sx={{ 
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'background.default',
      }}
    >
      {/* Header */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <IconButton
          onClick={() => navigate('/')}
          sx={{ 
            color: 'primary.main',
            '&:hover': { 
              bgcolor: 'primary.main',
              color: 'white',
            }
          }}
        >
          <ArrowLeft />
        </IconButton>
      </Box>

      <Container 
        maxWidth="sm" 
        sx={{ 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          py: 8,
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 4,
            p: { xs: 3, sm: 6 },
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
          }}
        >
          <Typography 
            variant="h4" 
            sx={{ 
              mb: 1,
              fontWeight: 700,
            }}
          >
            Create an account
          </Typography>
          <Typography 
            color="text.secondary"
            sx={{ mb: 4 }}
          >
            Join ShopFlair to get personalized recommendations
          </Typography>

          {errorMessage && (
            <Alert 
              severity="error" 
              sx={{ mb: 3 }}
              onClose={() => setErrorMessage('')}
            >
              {errorMessage}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <TextField
                name="name"
                label="Full name"
                value={formData.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <User size={20} />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />

              <TextField
                name="email"
                label="Email address"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Mail size={20} />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />

              <TextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={handleChange}
                error={!!errors.password}
                helperText={errors.password}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock size={20} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />

              <Typography variant="caption" color="text.secondary">
                By signing up, you agree to our{' '}
                <Link href="#" color="primary">Terms of Service</Link> and{' '}
                <Link href="#" color="primary">Privacy Policy</Link>
              </Typography>

              <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={isLoading}
                sx={{ 
                  py: 1.5,
                  bgcolor: 'primary.main',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                }}
              >
                {isLoading ? 'Creating account...' : 'Create account'}
              </Button>
            </Box>
          </form>

          <Box sx={{ mt: 4 }}>
            <Divider sx={{ mb: 4 }}>
              <Typography 
                variant="body2" 
                sx={{ 
                  px: 2,
                  color: 'text.secondary',
                }}
              >
                or
              </Typography>
            </Divider>

            <Button
              fullWidth
              variant="outlined"
              size="large"
              onClick={() => alert('Google sign-up')}
              sx={{ 
                py: 1.5,
                color: 'text.primary',
                borderColor: 'divider',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: 'background.default',
                },
              }}
            >
              Continue with Google
            </Button>
          </Box>

          <Typography 
            variant="body2" 
            align="center" 
            sx={{ mt: 4 }}
          >
            Already have an account?{' '}
            <Link 
              href="/signin"
              sx={{ 
                color: 'primary.main',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Sign in
            </Link>
          </Typography>
        </Box>
      </Container>

      {/* Confirmation Dialog */}
      <Dialog 
        open={showConfirmation} 
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h5" fontWeight="bold">
            Verify your email
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography color="text.secondary" sx={{ mb: 3 }}>
            We've sent a confirmation code to {formData.email}. Please enter it below to verify your account.
          </Typography>
          <TextField
            autoFocus
            fullWidth
            label="Confirmation Code"
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
            placeholder="Enter 6-digit code"
            sx={{ mb: 2 }}
          />
          {errorMessage && (
            <Alert 
              severity="error" 
              sx={{ mb: 2 }}
              onClose={() => setErrorMessage('')}
            >
              {errorMessage}
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmation} 
            variant="contained"
            disabled={!confirmationCode}
          >
            Verify Email
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SignUp;