import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { getCurrentUser } from "aws-amplify/auth";
import LandingPage from "./components/Landing/LandingPage";
import MainPage from "./components/MainPage";
import SignIn from "./components/Auth/SignIn";
import SignUp from "./components/Auth/SignUp";
import awsmobile from "./aws-exports";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./shared-theme/theme";
import CookieConsent from "./components/CookieConsent";
import PrivacyPolicy from "./components/appendix/PrivacyPolicy";
import TermsOfService from "./components/appendix/TermsOfService";
import GoogleAnalytics from './components/GoogleAnalytics';

Amplify.configure(awsmobile);
// AWIN folks, please approve our requests to be affiliates!
const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const currentUser = await getCurrentUser();
      setUser(currentUser);
    } catch (err) {
      setUser(null);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
      <GoogleAnalytics />
        <Authenticator.Provider>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/main"
              element={user ? <MainPage /> : <Navigate to="/signin" replace />}
            />
            <Route
              path="/signin"
              element={user ? <Navigate to="/main" replace /> : <SignIn />}
            />
            <Route exact path="/signup" element={<SignUp />} />

            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
          </Routes>
          <CookieConsent />
        </Authenticator.Provider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
