import React from 'react';
import { Container, Typography, Box, Link, Divider } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container className="max-w-4xl py-8 px-4">
      <Box className="mb-8">
        <Typography className="text-3xl font-bold mb-2">Privacy Policy</Typography>
        <Typography className="text-sm text-gray-500">Last Updated: October 13, 2024</Typography>
      </Box>

      <Box className="space-y-6">
        <Typography className="text-lg">
          This Privacy Policy describes how ShopFlair ("we," "our," or "us") collects, uses, and shares your personal information when you use our services.
        </Typography>

        <Box className="space-y-4">
          <Typography className="text-xl font-semibold" id="section-1">
            1. Information We Collect
          </Typography>
          <Box className="space-y-2">
            <Typography className="font-semibold">Information you provide to us:</Typography>
            <ul className="list-disc pl-6 space-y-2">
              <li>Account information (name, email, password)</li>
              <li>Shopping preferences and interests</li>
              <li>Product queries and search history</li>
              <li>Payment information when you subscribe</li>
            </ul>

            <Typography className="font-semibold mt-4">Information collected automatically:</Typography>
            <ul className="list-disc pl-6 space-y-2">
              <li>Device and browser information</li>
              <li>Usage data and interaction with our services</li>
              <li>IP address and location data</li>
              <li>Cookies and similar tracking technologies</li>
            </ul>
          </Box>

          <Typography className="text-xl font-semibold" id="section-2">
            2. How We Use Your Information
          </Typography>
          <Box className="space-y-2">
            <Typography>We use your information to:</Typography>
            <ul className="list-disc pl-6 space-y-2">
              <li>Provide and improve our AI shopping assistance services</li>
              <li>Personalize product recommendations</li>
              <li>Process your transactions and subscriptions</li>
              <li>Communicate with you about our services</li>
              <li>Analyze and improve our services</li>
              <li>Protect against fraud and unauthorized access</li>
            </ul>
          </Box>

          <Typography className="text-xl font-semibold" id="section-3">
            3. Information Sharing
          </Typography>
          <Typography>
            We do not sell your personal information. We may share your information with:
          </Typography>
          <ul className="list-disc pl-6 space-y-2">
            <li>Service providers who assist in operating our platform</li>
            <li>Partners who help provide product recommendations</li>
            <li>Law enforcement when required by law</li>
          </ul>

          <Typography className="text-xl font-semibold" id="section-4">
            4. Your Privacy Rights
          </Typography>
          <Typography>
            You have the right to:
          </Typography>
          <ul className="list-disc pl-6 space-y-2">
            <li>Access your personal information</li>
            <li>Correct inaccurate information</li>
            <li>Request deletion of your information</li>
            <li>Opt-out of certain data sharing</li>
            <li>Export your data</li>
          </ul>

          <Typography className="text-xl font-semibold" id="section-5">
            5. Data Security
          </Typography>
          <Typography>
            We implement appropriate technical and organizational measures to protect your personal information. However, no method of transmission over the Internet is 100% secure, and we cannot guarantee absolute security.
          </Typography>

          <Typography className="text-xl font-semibold" id="section-6">
            6. Cookies and Tracking
          </Typography>
          <Typography>
            We use cookies and similar technologies to enhance your experience, analyze usage, and assist in our marketing efforts. You can control cookies through your browser settings.
          </Typography>
        </Box>

        <Divider className="my-8" />

        <Box className="space-y-4">
          <Typography className="font-semibold">Contact Us</Typography>
          <Typography>
            If you have any questions about this Privacy Policy, please contact us at{' '}
            <Link href="mailto:privacy@shopflair.com" className="text-blue-600">
              privacy@shopflair.com
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;