import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';

const PromotionalBanner = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(90deg, #3d3393 0%, #34d89e 100%)',
        color: '#fff',
        py: 1,
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'Poppins, sans-serif',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              '& a': {
                color: 'inherit',
                textDecoration: 'underline',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                '&:hover': {
                  color: '#34FED9'
                }
              }
            }}
          >
            Get started on your first ShopFlair purchase with up to 30% discount{' '}
            <Link
              href="https://amzn.to/48CFLAg"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              Find it on
              <Box
                component="img"
                src="/images/amazon-logo.png"
                alt="Amazon"
                sx={{
                  height: '20px',
                  width: 'auto',
                  filter: 'brightness(1)', // Makes the logo white
                  verticalAlign: 'middle',
                }}
              />
            </Link>!
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default PromotionalBanner;
