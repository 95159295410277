// categoryResponses.js

export const categoryResponses = {
  "groceries": {
    "response": `🛒 **Smart Grocery Shopping Guide**

Current Trending Products & Shopping Tips:

1. **Healthy Alternatives**
   - {{Oatly Barista Edition}} - trending dairy alternative
   - {{Magic Spoon Cereal}} - high protein, low carb cereals
   - {{Hu Kitchen Dark Chocolate}} - clean ingredient chocolate

2. **Sustainable Kitchen**
   - {{Stasher Reusable Bags}} - eco-friendly storage
   - {{Blueland Cleaning Set}} - reduce plastic waste
   - {{Beeswrap Food Wraps}} - sustainable food storage

3. **Trending Snacks**
   - {{Perfect Bar}} - refrigerated protein bars
   - {{Lesser Evil Popcorn}} - clean ingredient snacks
   - {{Siete Tortilla Chips}} - grain-free chips

4. **Pantry Essentials**
   - {{Brightland Olive Oil}} - high-quality cooking oils
   - {{Maldon Sea Salt}} - chef-recommended seasoning
   - {{Fly By Jing Chili Crisp}} - viral condiment

5. **Meal Prep Tools**
   - {{Sistema Containers}} - meal prep containers
   - {{Instant Pot Pro}} - time-saving cooking
   - {{Food Saver System}} - reduce food waste

🌟 **Shopping Pro Tips:**
- Shop seasonal produce for best prices
- Compare unit prices instead of package prices
- Check store brands for better value
- Use grocery store apps for digital coupons
- Shop during off-peak hours for best selection`,
    "items": ["Oatly Barista Edition", "Stasher Reusable Bags", "Perfect Bar", "Instant Pot Pro"]
  },

  "medical": {
    "response": `🏥 **Health & Wellness Shopping Guide**

Essential Healthcare Products:

1. **First Aid Essentials**
   - {{First Aid Only Kit}} - comprehensive emergency care
   - {{3M Nexcare Bandages}} - advanced wound care
   - {{Neosporin Plus}} - infection prevention

2. **Health Monitoring**
   - {{Oximeter Pulse Monitor}} - oxygen monitoring
   - {{Omron Blood Pressure Monitor}} - home BP tracking
   - {{Braun Digital Thermometer}} - accurate temperature

3. **Wellness & Prevention**
   - {{Ritual Multivitamin}} - essential nutrients
   - {{Nordic Naturals Omega-3}} - heart health
   - {{Emergen-C Immune Plus}} - immune support

4. **Pain Management**
   - {{Biofreeze Pain Relief}} - topical pain relief
   - {{Thermacare Heat Wraps}} - muscle therapy
   - {{Voltaren Arthritis Gel}} - joint pain relief

5. **Sleep & Relaxation**
   - {{Philips SmartSleep Light}} - sleep therapy
   - {{Pure Enrichment Humidifier}} - breathing comfort
   - {{ThisWorks Deep Sleep Spray}} - sleep aid

🌟 **Healthcare Shopping Tips:**
- Check FSA/HSA eligibility
- Compare generic vs. brand names
- Verify expiration dates
- Keep emergency supplies stocked
- Consider automatic refills for prescriptions`,
    "items": ["First Aid Only Kit", "Omron Blood Pressure Monitor", "Ritual Multivitamin", "Pure Enrichment Humidifier"]
  },

  "pets": {
    "response": `🐾 **Pet Care Essentials Guide**

Must-Have Pet Products:

1. **Nutrition & Health**
   - {{Blue Buffalo Life Protection}} - premium dog food
   - {{Hill's Science Diet}} - veterinary recommended
   - {{Purina Pro Plan}} - specialized nutrition

2. **Care & Grooming**
   - {{Furminator Brush}} - professional deshedding
   - {{Wahl Clipper Kit}} - home grooming
   - {{TropiClean Pet Shampoo}} - natural ingredients

3. **Health & Wellness**
   - {{Frontline Plus}} - flea/tick prevention
   - {{Greenies Dental Treats}} - oral health
   - {{Cosequin Joint Health}} - mobility support

4. **Smart Pet Tech**
   - {{Furbo Dog Camera}} - pet monitoring
   - {{Whistle GPS Tracker}} - location tracking
   - {{PetSafe Smart Feeder}} - automated feeding

5. **Comfort & Play**
   - {{KONG Classic}} - durable toys
   - {{Casper Dog Bed}} - orthopedic comfort
   - {{Catit Flower Fountain}} - water fountain

🌟 **Pet Care Shopping Tips:**
- Subscribe for regular deliveries
- Check size guidelines carefully
- Read ingredient labels
- Consider pet age and health needs
- Look for vet-recommended products`,
    "items": ["Blue Buffalo Life Protection", "Furminator Brush", "Frontline Plus", "KONG Classic"]
  },

  "fashion": {
    "response": `👗 **Fashion & Beauty Trends Guide**

Current Style Essentials:

1. **Sustainable Fashion**
   - {{Allbirds Runners}} - eco-friendly footwear
   - {{Everlane Basics}} - transparent pricing
   - {{Patagonia Fleece}} - sustainable outerwear

2. **Beauty Must-Haves**
   - {{Charlotte Tilbury Magic Cream}} - luxury skincare
   - {{Rare Beauty Blush}} - clean beauty
   - {{Dyson Airwrap}} - premium styling

3. **Trending Accessories**
   - {{Mejuri Jewelry}} - fine everyday pieces
   - {{Ray-Ban Stories}} - smart eyewear
   - {{Cuyana Leather Tote}} - minimal bags

4. **Activewear**
   - {{Lululemon Align Leggings}} - yoga/lifestyle
   - {{Nike Metcon}} - training shoes
   - {{Girlfriend Collective Sets}} - sustainable active

5. **Skincare Tech**
   - {{NuFace Trinity}} - facial toning
   - {{LED Face Mask}} - light therapy
   - {{Foreo Luna}} - sonic cleansing

🌟 **Style Shopping Tips:**
- Check size guides carefully
- Read fabric care instructions
- Look for versatile pieces
- Consider cost per wear
- Check return policies`,
    "items": ["Allbirds Runners", "Charlotte Tilbury Magic Cream", "Lululemon Align Leggings", "NuFace Trinity"]
  },

  "home": {
    "response": `🏠 **Home & DIY Essentials Guide**

Top Home Improvement Products:

1. **Smart Home**
   - {{Ring Video Doorbell Pro}} - security
   - {{Philips Hue Starter Kit}} - smart lighting
   - {{Nest Learning Thermostat}} - climate control

2. **DIY Tools**
   - {{DeWalt 20V Max Kit}} - power tools
   - {{Kreg Pocket Hole System}} - joinery
   - {{Milwaukee Tool Set}} - hand tools

3. **Organization**
   - {{SimpleHuman Trash Can}} - waste management
   - {{Container Store Bins}} - storage
   - {{Elfa System}} - closet organization

4. **Home Comfort**
   - {{Dyson Air Purifier}} - air quality
   - {{Brooklinen Sheets}} - bedding
   - {{Vitamix Blender}} - kitchen essential

5. **Garden & Outdoor**
   - {{Weber Grill}} - outdoor cooking
   - {{Ego Power+ Tools}} - lawn care
   - {{Gardener's Supply Set}} - gardening

🌟 **Home Shopping Tips:**
- Measure spaces carefully
- Check warranty terms
- Compare energy ratings
- Read installation requirements
- Consider maintenance costs`,
    "items": ["Ring Video Doorbell Pro", "DeWalt 20V Max Kit", "Dyson Air Purifier", "Weber Grill"]
  },

  "electronics": {
    "response": `💻 **Electronics & Tech Guide**

Latest Tech Recommendations:

1. **Computing**
   - {{MacBook Air M2}} - portable computing
   - {{Dell XPS 13}} - premium Windows
   - {{iPad Pro M2}} - versatile tablet

2. **Smart Home Tech**
   - {{Amazon Echo Show}} - smart display
   - {{Google Nest Hub}} - home control
   - {{Arlo Pro 4}} - security cameras

3. **Entertainment**
   - {{LG C2 OLED TV}} - premium display
   - {{Sonos Arc}} - soundbar
   - {{Sony WH-1000XM5}} - headphones

4. **Mobile & Accessories**
   - {{iPhone 15 Pro}} - flagship phone
   - {{Samsung S24 Ultra}} - Android flagship
   - {{Anker Power Bank}} - portable power

5. **Productivity**
   - {{LG Ultrafine Monitor}} - display
   - {{Logitech MX Master}} - mouse
   - {{Keychron K2}} - mechanical keyboard

🌟 **Tech Shopping Tips:**
- Compare specs carefully
- Check compatibility
- Read expert reviews
- Consider future upgrades
- Verify warranty coverage`,
    "items": ["MacBook Air M2", "Amazon Echo Show", "Sony WH-1000XM5", "Logitech MX Master"]
  },

  "gaming": {
    "response": `🎮 **Gaming & Entertainment Guide**

Top Gaming Essentials:

1. **Gaming Systems**
   - {{PS5 Console}} - next-gen gaming
   - {{Xbox Series X}} - powerful console
   - {{Nintendo Switch OLED}} - portable

2. **Gaming Gear**
   - {{Razer BlackShark V2}} - headset
   - {{SteelSeries Apex Pro}} - keyboard
   - {{Logitech G Pro X}} - mouse

3. **Streaming Setup**
   - {{Elgato HD60 X}} - capture card
   - {{Blue Yeti X}} - microphone
   - {{Ring Light}} - lighting

4. **VR Gaming**
   - {{Meta Quest 3}} - wireless VR
   - {{PSVR2}} - console VR
   - {{HP Reverb G2}} - PC VR

5. **Music & Audio**
   - {{Audio-Technica LP120X}} - turntable
   - {{Sonos One}} - smart speaker
   - {{Shure SM7B}} - pro microphone

🌟 **Gaming Shopping Tips:**
- Check system requirements
- Compare online services
- Read user reviews
- Consider storage needs
- Look for bundle deals`,
    "items": ["PS5 Console", "Razer BlackShark V2", "Meta Quest 3", "Sonos One"]
  },

  "books": {
    "response": `📚 **Books & Reading Guide**

Reading Essentials:

1. **E-Readers**
   - {{Kindle Paperwhite}} - best e-reader
   - {{Kobo Libra 2}} - alternative choice
   - {{Kindle Oasis}} - premium reading

2. **Book Storage**
   - {{Floating Bookshelves}} - display
   - {{Library Cart}} - mobile storage
   - {{Book Ends}} - organization

3. **Reading Accessories**
   - {{Book Light}} - night reading
   - {{Reading Pillow}} - comfort
   - {{Book Stand}} - hands-free

4. **Study Tools**
   - {{Rocketbook Smart Notebook}} - digital notes
   - {{Book Darts}} - page markers
   - {{Reading Timer}} - focus aid

5. **Audio Books**
   - {{Audible Premium Plus}} - subscription
   - {{Bluetooth Earbuds}} - listening
   - {{Speaker Stand}} - audio setup

🌟 **Reading Shopping Tips:**
- Compare digital subscriptions
- Check device compatibility
- Consider lighting needs
- Look for bundle deals
- Read return policies`,
    "items": ["Kindle Paperwhite", "Book Light", "Rocketbook Smart Notebook", "Audible Premium Plus"]
  },

  "toys": {
    "response": `👶 **Toys & Baby Essentials Guide**

Top Products for Kids:

1. **Educational Toys**
   - {{LEGO Education Set}} - building skills
   - {{Osmo Learning Kit}} - interactive learning
   - {{Magna-Tiles}} - magnetic building

2. **Baby Gear**
   - {{UPPAbaby Vista V2}} - stroller
   - {{4moms mamaRoo}} - baby swing
   - {{Hatch Rest}} - sound machine

3. **Active Play**
   - {{Micro Kickboard Scooter}} - outdoor fun
   - {{Little Tikes Trampoline}} - indoor active
   - {{Radio Flyer Wagon}} - classic toy

4. **STEM Toys**
   - {{Snap Circuits}} - electronics
   - {{National Geographic Kits}} - science
   - {{Code & Go Robot Mouse}} - coding

5. **Creative Play**
   - {{Play-Doh Super Set}} - modeling
   - {{Crayola Creation Station}} - art
   - {{KiwiCo Crates}} - projects

🌟 **Shopping Tips:**
- Check age recommendations
- Verify safety certifications
- Read parent reviews
- Consider skill level
- Look for educational value`,
    "items": ["LEGO Education Set", "UPPAbaby Vista V2", "Micro Kickboard Scooter", "KiwiCo Crates"]
  },

  "automotive": {
    "response": `🚗 **Automotive Care Guide**

Essential Car Products:

1. **Car Maintenance**
   - {{NOCO Boost Plus}} - jump starter
   - {{Michelin Premier Tires}} - all-season
   - {{Mobil 1 Synthetic Oil}} - engine care

2. **Car Tech**
   - {{Garmin DriveSmart}} - GPS
   - {{VIOFO Dash Cam}} - recording
   - {{OBD2 Scanner}} - diagnostics

3. **Car Care**
   - {{Chemical Guys Kit}} - cleaning
   - {{Meguiar's Ultimate}} - wax
   - {{Armor All Set}} - protection

4. **Emergency Kit**
   - {{AAA Road Kit}} - emergency
   - {{LifeHammer Tool}} - safety
   - {{LED Road Flares}} - visibility

5. **Interior Care**
   - {{WeatherTech Mats}} - floor protection
   - {{Vacuum Cleaner}} - cleaning
   - {{Car Organizer}} - storage

🌟 **Auto Shopping Tips:**
- Check vehicle compatibility
- Compare warranties
- Read expert reviews
- Consider climate needs
- Verify fitment guides`,
    "items": ["NOCO Boost Plus", "VIOFO Dash Cam", "Chemical Guys Kit", "WeatherTech Mats"]
  },

  "office": {
    "response": `💼 **Office & Professional Guide**

Workspace Essentials:

1. **Ergonomic Setup**
   - {{Herman Miller Aeron}} - chair
   - {{Fully Jarvis Desk}} - standing desk
   - {{LG Ultrawide Monitor}} - display

2. **Tech Essentials**
   - {{MacBook Pro}} - laptop
   - {{iPad Air}} - tablet
   - {{Brother Laser Printer}} - printing

3. **Organization**
   - {{Poppin Desktop Set}} - accessories
   - {{Filing Cabinet}} - storage
   - {{Label Maker}} - organization

4. **Productivity Tools**
   - {{Rocketbook Notebook}} - smart notes
   - {{Logitech Webcam}} - video calls
   - {{Blue Yeti Mic}} - audio

5. **Office Comfort**
   - {{Desk Lamp}} - lighting
   - {{Foot Rest}} - ergonomics
   - {{Monitor Stand}} - setup

🌟 **Office Shopping Tips:**
- Measure workspace carefully
- Check ergonomic ratings
- Consider power needs
- Look for warranty terms
- Read user reviews`,
    "items": ["Herman Miller Aeron", "Fully Jarvis Desk", "Rocketbook Notebook", "Blue Yeti Mic"]
  },

  "sports": {
    "response": `🏃‍♂️ **Sports & Outdoor Guide**

Active Lifestyle Essentials:

1. **Fitness Gear**
   - {{Garmin Forerunner 255}} - running watch
   - {{Powerblock Dumbbells}} - adjustable weights
   - {{Peloton Bike}} - home cardio

2. **Outdoor Adventure**
   - {{Osprey Backpack}} - hiking gear
   - {{Yeti Cooler}} - outdoor storage
   - {{Black Diamond Headlamp}} - lighting

3. **Sports Equipment**
   - {{Wilson Evolution}} - basketball
   - {{TaylorMade Golf Set}} - golf clubs
   - {{Nike Mercurial}} - soccer cleats

4. **Recovery Tools**
   - {{Theragun Prime}} - massage
   - {{Hyperice X}} - compression
   - {{Foam Roller Pro}} - muscle care

5. **Camping Gear**
   - {{REI Tent}} - shelter
   - {{Sleeping Bag}} - comfort
   - {{JetBoil Stove}} - cooking

🌟 **Sports Shopping Tips:**
- Check size and fit guides
- Compare warranty coverage
- Read activity-specific reviews
- Consider seasonal needs
- Look for durability ratings`,
    "items": ["Garmin Forerunner 255", "Osprey Backpack", "Theragun Prime", "REI Tent"]
  }
};