import { generateClient } from 'aws-amplify/api';
import Cookies from 'js-cookie';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

class BrowserTracker {
  static async getBrowserId() {
    // Check existing DATR-style cookie first
    let browserId = Cookies.get('_datr');
    
    if (!browserId) {
      // Generate browser fingerprint if no cookie exists
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      browserId = result.visitorId;
      
      // Set cookie with 2 year expiry (similar to DATR)
      Cookies.set('_datr', browserId, { 
        expires: 730, // 2 years
        sameSite: 'Lax',
        secure: true
      });
      
      // Store first visit timestamp
      Cookies.set('_first_visit', new Date().toISOString(), { 
        expires: 730,
        sameSite: 'Lax',
        secure: true
      });
    }

    // Update last visit timestamp
    Cookies.set('_last_visit', new Date().toISOString(), {
      expires: 730,
      sameSite: 'Lax',
      secure: true
    });

    return browserId;
  }

  static getBrowserInfo() {
    return {
      userAgent: navigator.userAgent,
      language: navigator.language,
      platform: navigator.platform,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      cookiesEnabled: navigator.cookieEnabled,
      firstVisit: Cookies.get('_first_visit'),
      lastVisit: Cookies.get('_last_visit')
    };
  }

  static getSessionId() {
    let sessionId = sessionStorage.getItem('_sid');
    if (!sessionId) {
      sessionId = crypto.randomUUID();
      sessionStorage.setItem('_sid', sessionId);
    }
    return sessionId;
  }
}

export default BrowserTracker;
