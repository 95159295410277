import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "lucide-react";

const ProductCard = ({ product }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  const handleClick = (e) => {
    e.preventDefault();
    window.open(product.link, "_blank");
  };

  return (
    <Card
      sx={{
        width: { xs: "140px", sm: "180px" },
        height: { xs: "280px", sm: "320px" },
        flexShrink: 0,
        zIndex: 3,
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        transform: isHovered ? "scale(1.05)" : "scale(1)",
        boxShadow: isHovered
          ? `0 8px 16px ${theme.palette.primary.main}20`
          : `0 4px 8px ${theme.palette.primary.main}10`,
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        borderRadius: '12px',
        overflow: 'hidden',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          pt: "4px",
          bgcolor: theme.palette.background.paper,
          flexGrow: 0,
          height: { xs: "120px", sm: "140px" },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CardMedia
          component="img"
          image={product.image}
          alt={product.name}
          onClick={handleClick}
          sx={{
            cursor: "pointer",
            objectFit: "contain",
            height: "100%",
            width: "100%",
            padding: 1,
          }}
        />
      </Box>
      <CardContent
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Typography
          variant="body2"
          fontWeight="bold"
          onClick={handleClick}
          sx={{
            cursor: "pointer",
            mb: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            height: "2.5em",
            color: theme.palette.text.primary,
          }}
        >
          {product.name}
        </Typography>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 'bold',
              }}
            >
              {product.price}
            </Typography>
            {product.rating && (
              <Typography variant="body2">
              <span style={{ color: theme.palette.gold.main }}>
                {"★".repeat(product.rating)}
              </span>
              <span style={{ color: theme.palette.primary.main }}> ({product.rating})</span>
            </Typography>

            )}
          </Box>
          <Typography
            variant="caption"
            sx={{
              color: theme.palette.text.secondary,
              display: 'block',
            }}
          >
            {product.seller}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const ProductSwiper = ({ productData }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const numVisibleProducts = isDesktop ? 5 : 3;
  const [startIndex, setStartIndex] = useState(0);

  const visibleProducts = productData.slice(
    startIndex,
    startIndex + numVisibleProducts
  );

  const handlePrev = () => {
    setStartIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const handleNext = () => {
    setStartIndex((prevIndex) =>
      Math.min(productData.length - numVisibleProducts, prevIndex + 1)
    );
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        p: { xs: 2, sm: 3 },
        backgroundColor: theme.palette.background.default,
        borderRadius: '12px',
        mt: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: { xs: 1, sm: 2 },
          justifyContent: "center",
          overflowX: "hidden",
          px: { xs: 4, sm: 6 }, // Space for navigation buttons
        }}
      >
        {visibleProducts.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </Box>
      <IconButton
        sx={{
          position: "absolute",
          left: { xs: 0, sm: 8 },
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 2,
          bgcolor: theme.palette.background.paper,
          boxShadow: `0 2px 8px ${theme.palette.primary.main}20`,
          color: theme.palette.primary.main,
          '&:hover': {
            bgcolor: theme.palette.background.paper,
            boxShadow: `0 4px 12px ${theme.palette.primary.main}30`,
          },
          '&:disabled': {
            bgcolor: theme.palette.background.paper,
            opacity: 0.5,
          },
        }}
        onClick={handlePrev}
        disabled={startIndex === 0}
      >
        <ChevronLeft />
      </IconButton>
      <IconButton
        sx={{
          position: "absolute",
          right: { xs: 0, sm: 8 },
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 2,
          bgcolor: theme.palette.background.paper,
          boxShadow: `0 2px 8px ${theme.palette.primary.main}20`,
          color: theme.palette.primary.main,
          '&:hover': {
            bgcolor: theme.palette.background.paper,
            boxShadow: `0 4px 12px ${theme.palette.primary.main}30`,
          },
          '&:disabled': {
            bgcolor: theme.palette.background.paper,
            opacity: 0.5,
          },
        }}
        onClick={handleNext}
        disabled={startIndex >= productData.length - numVisibleProducts}
      >
        <ChevronRight />
      </IconButton>
    </Box>
  );
};

export default ProductSwiper;
