// Log initial query
export const logUserQuery = /* GraphQL */ `
  mutation CreateQueryLog(
    $input: CreateQueryLogInput!
  ) {
    createQueryLog(input: $input) {
      id
      queryText
      response
      ds
      unixTime
      browserInfo
      queryType
      browserId
      sessionId
      userId
    }
  }
`;

// Update with API response
export const logQueryResponse = /* GraphQL */ `
  mutation UpdateQueryLog(
    $input: UpdateQueryLogInput!
  ) {
    updateQueryLog(input: $input) {
      id
      queryText
      response
      responseTimeMs
      queryType
    }
  }
`;

// Log user feedback
export const logQueryFeedback = /* GraphQL */ `
  mutation UpdateQueryLogFeedback(
    $input: UpdateQueryLogInput!
  ) {
    updateQueryLog(input: $input) {
      id
      queryText
      response
      feedback
      queryType
    }
  }
`;

export const checkRateLimit = /* GraphQL */ `
  mutation CheckRateLimit($input: RateLimitCheckInput!) {
    checkRateLimit(input: $input) {
      success
      message
      allowed
    }
  }
`;