import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#190750', // Header background
      light: '#2f0d96',
      dark: '#0d0328',
    },
    secondary: {
      main: '#34FED9', // Secondary for buttons
      light: '#61FEE3',
      dark: '#01E7BC',
    },
    gold: {
      main: '#FEBD14', // Secondary for buttons
      light: '#FEBD14',
      dark: '#FEBD14',
    },
    accent: {
      main: '#3e5ee2',
      light: '#7189ea',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F8F9FC',
      highlight: '#F0F2F9',
    },
    text: {
      primary: '#190750',
      secondary: '#8995a6',
    },
  },
  typography: {
    fontFamily: "'Poppins', 'Avenir-Pro', sans-serif", // Updated font family
    h1: {
      fontFamily: 'Avenir-Pro, sans-serif',
      fontSize: '3.5rem',
      fontWeight: 800,
      letterSpacing: '-0.02em',
      lineHeight: 1.2,
      color: '#FFFFFF', // Header text color
      '@media (max-width:600px)': {
        fontSize: '2.5rem',
      },
    },
    h2: {
      fontFamily: 'Avenir-Pro, sans-serif',
      fontSize: '2.5rem',
      fontWeight: 700,
      letterSpacing: '-0.01em',
    },
    subtitle1: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '1.25rem',
      lineHeight: 1.6,
      color: '#8995a6',
    },
    body1: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '1rem',
      lineHeight: 1.7,
    },
    button: {
      fontFamily: 'Poppins, sans-serif',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '1rem',
          fontWeight: 600,
          padding: '10px 24px',
          fontFamily: 'Poppins, sans-serif',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0 4px 12px rgba(25, 7, 80, 0.15)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: '0 4px 20px rgba(25, 7, 80, 0.08)',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
          },
        },
      },
    },
  },
});

export default theme;
