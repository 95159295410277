import React from 'react';
import { Container, Typography, Box, Link, Divider } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container className="max-w-4xl py-8 px-4">
      <Box className="mb-8">
        <Typography className="text-3xl font-bold mb-2">Terms of Service</Typography>
        <Typography className="text-sm text-gray-500">Effective October 13, 2024</Typography>
      </Box>

      <Box className="space-y-6">
        <Typography className="text-lg">
          Welcome to ShopFlair! Before you access our services, please read these Terms of Service.
        </Typography>

        <Box className="space-y-4">
          <Typography className="text-xl font-semibold" id="section-1">
            1. Who we are
          </Typography>
          <Typography>
            ShopFlair is an AI-powered shopping assistant platform working to help users make informed purchasing decisions. We conduct advanced product research, develop personalized recommendations, and deploy intelligent shopping assistance through our platform.
          </Typography>

          <Typography className="text-xl font-semibold" id="section-2">
            2. Account Creation and Access
          </Typography>
          <Box className="space-y-2">
            <Typography className="font-semibold">Minimum Age</Typography>
            <Typography>
              You must be at least 18 years old or the minimum age required to consent to use the Services in your location, whichever is higher.
            </Typography>
            
            <Typography className="font-semibold">Your ShopFlair Account</Typography>
            <Typography>
              To access our Services, we may ask you to create an Account. You agree to provide correct, current, and complete Account information and allow us to use it to communicate with you about our Services.
            </Typography>
          </Box>

          <Typography className="text-xl font-semibold" id="section-3">
            3. Use of our Services
          </Typography>
          <Typography>
            You may access and use our Services only in compliance with our Terms, our Acceptable Use Policy, and any guidelines or supplemental terms we may post on the Services (the "Permitted Use").
          </Typography>

          <Typography className="text-xl font-semibold" id="section-4">
            4. Content and Materials
          </Typography>
          <Typography>
            Our AI shopping assistant may generate responses based on your queries ("Outputs"). You are responsible for all queries you submit to our Services. You acknowledge that AI recommendations are provided as suggestions only and should be independently verified.
          </Typography>

          <Typography className="text-xl font-semibold" id="section-5">
            5. Subscriptions and Payments
          </Typography>
          <Box className="space-y-2">
            <Typography>
              Some features of our Services may require a subscription. When you sign up for a paid subscription:
            </Typography>
            <Typography>
            <ul className="list-disc pl-6 space-y-2">
              <li>Subscriptions automatically renew unless cancelled</li>
              <li>You will be billed in advance for each subscription period</li>
              <li>You can cancel at any time through your account settings</li>
            </ul>
          </Typography>
          </Box>
          <Typography className="text-xl font-semibold" id="section-6">
            6. Disclaimer of Warranties
          </Typography>
          <Typography className="uppercase">
            THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND. WE DO NOT GUARANTEE THE ACCURACY OF ANY RECOMMENDATIONS OR PRODUCT INFORMATION PROVIDED THROUGH OUR SERVICES.
          </Typography>

          <Typography className="text-xl font-semibold" id="section-7">
            7. Limitation of Liability
          </Typography>
          <Typography className="uppercase">
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL WE BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF OR RELATING TO YOUR USE OF THE SERVICES.
          </Typography>
        </Box>

        <Divider className="my-8" />

        <Box className="space-y-4">
          <Typography className="font-semibold">Contact Us</Typography>
          <Typography>
            If you have any questions about these Terms, please contact us at{' '}
            <Link href="mailto:support@shopflairapp.com" className="text-blue-600">
              support@shopflairapp.com
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default TermsOfService;