import React, { useState, useEffect, useRef } from "react";
import { useQueryLog } from "../../services/QueryLogger";
import BrowserTracker from "../../services/BrowserTracker";
import { getCurrentUser } from "aws-amplify/auth"; // Add this import
import RegistrationWall from "./RegistrationWall";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Card,
  Collapse,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  MessageSquare,
  ShoppingBag,
  Zap,
  ChevronDown,
  ChevronUp,
} from "lucide-react";
import TemplateFrame from "../TemplateFrame";
import ProductSwiper from "./ProductSwiper";
import SuggestionCards from "./SuggestionCards";
import Footer from "../Footer";
import ReactMarkdown from "react-markdown";
import card_responses from "../../data/card_responses.json";
import product_data from "../../data/product_data.json";
import ResponseSection from "./ResponseSection";
import PopularQuestionsSection from "./PopularQuestionsSection";
import CategorySection from "./CategorySection";
import { generateClient } from "aws-amplify/api";
import { checkRateLimit } from "../../graphql/mutations"; // Make sure to generate this mutation

const client = generateClient();
const API_ENDPOINT = process.env.REACT_APP_OPENAI_FUNCTION_API_ENDPOINT;

// Existing helper functions
const GetCardData = () => {
  return card_responses.map((card, index) => ({
    id: index,
    key: card.key,
    text: card.user_prompt,
    answer: card.api_response.response,
    items: card.api_response.items,
  }));
};

const GetProductData = (key) => {
  return product_data[key].products
    .sort((a, b) => b.rating - a.rating)
    .map((product, index) => ({
      id: index,
      name: product.name,
      price: product.price,
      image: product.image,
      seller: product.seller,
      rating: product.rating,
      link: product.link,
    }));
};

// Feature Card Component
const FeatureCard = ({ icon, title, description }) => (
  <Card
    sx={{
      p: 3,
      display: "flex",
      alignItems: "flex-start",
      gap: 2,
      bgcolor: "background.paper",
      transition: "transform 0.2s",
      "&:hover": {
        transform: "translateY(-4px)",
      },
    }}
  >
    <Box sx={{ color: "primary.main" }}>{icon}</Box>
    <Box>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </Box>
  </Card>
);

// Question Card Component
const QuestionCard = ({ question, onClick }) => (
  <Card
    onClick={onClick}
    sx={{
      p: 3,
      cursor: "pointer",
      transition: "all 0.2s",
      "&:hover": {
        transform: "translateY(-4px)",
        bgcolor: "background.highlight",
      },
    }}
  >
    <Typography variant="body1" fontWeight={500}>
      {question}
    </Typography>
  </Card>
);

// Main Landing Page Component
const LandingPage = () => {
  // Existing state
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [displayedAnswer, setDisplayedAnswer] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [productData, setProductData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedInputValue, setSelectedInputValue] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);

  const [userId, setUserId] = useState(null);
  const [browserId, setBrowserId] = useState(null);
  const [sessionId, setSessionId] = useState(null);

  const responseSectionRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const cardData = GetCardData();

  const [queryCount, setQueryCount] = useState(0);
  const [showRegistrationWall, setShowRegistrationWall] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // initiate QueryLogger
  const {
    logQuery,
    logResponse,
    logFeedback,
    currentQueryId,
    loading,
    loggingError,
  } = useQueryLog();

  // check auth status
  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      await getCurrentUser();
      setIsAuthenticated(true);
    } catch {
      setIsAuthenticated(false);
    }
  };

  // Initialize browser and session tracking
  useEffect(() => {
    const initializeTracking = async () => {
      try {
        // Get or create browser ID (DATR)
        const bid = await BrowserTracker.getBrowserId();
        setBrowserId(bid);

        // Get or create session ID
        const sid = BrowserTracker.getSessionId();
        setSessionId(sid);

        // Try to get authenticated user
        try {
          const user = await getCurrentUser();
          setUserId(user.userId);
        } catch (error) {
          // User not authenticated, will use anonymous
          console.log("getCurrentUser failed, No authenticated user");
        }
      } catch (error) {
        console.error("Error initializing tracking:", error);
      }
    };

    initializeTracking();
  }, []);

  // Existing functions
  const scrollToResults = () => {
    if (responseSectionRef.current) {
      responseSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleCustomQuerySubmit = async (event) => {
    if (event.key === "Enter" || event.type === "click") {
      if (!isAuthenticated && queryCount >= 5) {
        setShowRegistrationWall(true);
        return;
      }

      setSelectedInputValue(inputValue);
      setIsLoading(true);
      setProductData([]);
      setDisplayedAnswer("");
      scrollToResults();

      try {
        // 1. First check rate limit
        // const rateLimitCheck = await client.graphql({
        //   query: checkRateLimit,
        //   variables: {
        //     input: {
        //       requestId: uuidv4(), // Add uuid import if not already present
        //       apiKey: process.env.REACT_APP_API_KEY
        //     }
        //   },
        //   authMode: 'apiKey' // Explicitly set auth mode
        // });

        // if (!rateLimitCheck.data.checkRateLimit.allowed) {
        //   setErrorCode("Rate limit exceeded. Please try again later.");
        //   return;
        // }

        // 2. log the user query
        const queryId = await logQuery(inputValue);

        const startTime = performance.now();
        const response = await fetch(API_ENDPOINT, {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
            },
          body: JSON.stringify({ prompt: inputValue }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const response_data = await response.json();
        const responseTime = Math.round(performance.now() - startTime);

        // 3. Log the successful response
        try {
          await logResponse(queryId, response_data.data.response, responseTime);
        } catch (loggingErr) {
          console.log("Error logging response:", loggingErr);
        }

        setSelectedAnswer(response_data.data.response);
        setIsTyping(true);

        if (!isAuthenticated) {
          setQueryCount((prev) => prev + 1);
        }
      } catch (err) {
        setErrorCode(
          err.message || "An error occurred while fetching the data"
        );
        console.error("Error:", err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Typing effect
  useEffect(() => {
    if (isTyping && selectedAnswer) {
      const timeoutId = setTimeout(() => {
        setDisplayedAnswer((prev) => {
          if (prev.split(" ").length < selectedAnswer.split(" ").length) {
            return selectedAnswer
              .split(" ")
              .slice(0, prev.split(" ").length + 1)
              .join(" ");
          } else {
            setIsTyping(false);
            return prev;
          }
        });
      }, 1);
      return () => clearTimeout(timeoutId);
    }
  }, [isTyping, selectedAnswer, displayedAnswer]);

  // Add this state for tracking loading question
  const [loadingQuestion, setLoadingQuestion] = useState("");

  const handleQuestionClick = async (card) => {
    setLoadingQuestion(card.text);
    setInputValue(card.text);
    setSelectedInputValue(card.text);
    setDisplayedAnswer("");
    scrollToResults();

    // Simulate a brief loading state for better UX
    setIsLoading(true);

    // Short timeout to show loading state and ensure smooth scroll
    setTimeout(() => {
      // Use the cached response from card data
      setSelectedAnswer(card.answer);
      setSelectedItems(card.items || []);
      setProductData(() => GetProductData(card.key));
      setIsTyping(true);
      setIsLoading(false);
      setLoadingQuestion("");
    }, 500);
  };

  // Track when users mark responses as helpful/unhelpful
  const handleFeedback = async (isHelpful) => {
    try {
      if (currentQueryId) {
        await logFeedback(currentQueryId, isHelpful ? 'helpful' : 'unhelpful');
      }
    } catch (error) {
      console.error("Error logging feedback:", error);
    }
  };

  // Add a queries remaining indicator
  const QueriesRemaining = () => {
    if (isAuthenticated) return null;

    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mt: 2 }}
      >
        {queryCount < 5
          ? `${5 - queryCount} free queries remaining`
          : "No free queries remaining. Sign up for unlimited access!"}
      </Typography>
    );
  };

  return (
    <TemplateFrame>
      {/* Hero Section */}
<Box
  sx={{
    background: "linear-gradient(145deg, #F8F9FC 0%, #F0F2F9 100%)",
    position: "relative",
    overflow: "hidden",
  }}
>
  {/* Hero Image Section */}
  <Box
    sx={{
      width: '100%',
      height: { xs: '200px', sm: '250px', md: '300px' },
      position: 'relative',
      mb: { xs: 4, md: 6 },  // Add margin bottom for spacing from content
    }}
  >
    <Box
      component="img"
      src="/images/hero-bg.png"
      alt="Decorative header"
      sx={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center 20%',
      }}
    />
    {/* Gradient overlay */}
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(248, 249, 252, 0) 60%, rgba(248, 249, 252, 1) 100%)',
      }}
    />
  </Box>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { md: "1fr 1fr" },
              gap: 6,
              alignItems: "center",
              pt: { xs: 2, md: 4 },  // Adjusted padding top
              pb: { xs: 6, md: 8 },  // Keep existing padding bottom
            }}
          >
            <Box>
              <Typography
                variant="h1"
                sx={{
                  mb: 3,
                  background:
                    "linear-gradient(90deg, #2D3250 0%, #424769 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Your AI Shopping Assistant
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 4 }}>
                Get personalized product recommendations and expert advice
                powered by AI. Ask anything about products you're interested in.
              </Typography>

              {/* Main Query Input */}
              <Card
  sx={{
    p: { xs: 3, sm: 4 },
    bgcolor: "background.paper",
    boxShadow: "0 8px 32px rgba(45, 50, 80, 0.12)",
    maxWidth: 600,
    minHeight: { xs: 180, sm: 200 },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    my: { xs: 2, sm: 3 },
  }}
>
  <Typography
    variant="h6"
    sx={{
      mb: { xs: 2.5, sm: 3 },
      fontSize: { xs: '1.1rem', sm: '1.25rem' }
    }}
  >
    What are you looking for?
  </Typography>
  <Box sx={{
    display: "flex",
    gap: 2,
    alignItems: 'center',  // Center items vertically
  }}>
    <TextField
      fullWidth
      multiline  // Enable multiline
      rows={3}   // Set number of rows
      placeholder="e.g. What's the best gaming laptop under $1000?"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onKeyPress={handleCustomQuerySubmit}
      sx={{
        "& .MuiOutlinedInput-root": {
          bgcolor: "background.default",
          height: { xs: '96px', sm: '112px' },
          "& textarea": {  // Style the textarea element
            height: "100% !important",
          },
          "& .MuiOutlinedInput-input": {  // Style the input/placeholder
            overflow: "auto !important",
            textOverflow: "unset !important",
            whiteSpace: "pre-wrap !important",
          }
        },
      }}
    />
    <Button
      variant="contained"
      size="large"
      sx={{
        padding: { xs: "8px 16px", sm: "12px 24px" },
        fontSize: { xs: "0.875rem", sm: "1rem" },
        bgcolor: theme.palette.secondary.main,
        color: "primary.main",
        height: { xs: '48px', sm: '56px' },
        alignSelf: 'center',  // Ensure button stays centered
      }}
      onClick={handleCustomQuerySubmit}
    >
      Ask AI
    </Button>
  </Box>
</Card>
            </Box>

            {/* Feature Highlights */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: 3,
                mt: { xs: 4, md: 0 },
              }}
            >
              <FeatureCard
                icon={<MessageSquare />}
                title="AI-Powered Advice"
                description="Get expert recommendations tailored to your needs"
              />
              <FeatureCard
                icon={<ShoppingBag />}
                title="Best Deals"
                description="Find the perfect products at the best prices"
              />
              <FeatureCard
                icon={<Zap />}
                title="Quick & Easy"
                description="Save time with instant, personalized suggestions"
              />
            </Box>
          </Box>
        </Container>
      </Box>
      {/* Sample Queries Section */}
      <PopularQuestionsSection
        cardData={cardData}
        onQuestionClick={handleQuestionClick}
        loadingQuestion={loadingQuestion}
      />
      {/* Response Section */}
      <Container maxWidth="lg" sx={{ py: 4 }} ref={responseSectionRef}>
        <ResponseSection
          displayedAnswer={displayedAnswer}
          selectedInputValue={selectedInputValue}
          isTyping={isTyping}
          isLoading={isLoading}
          productData={productData}
          selectedItems={selectedItems}
        />
      </Container>

      {/* Categories Section */}
      <CategorySection
        setInputValue={setInputValue}
        scrollToResults={scrollToResults}
      />

      <Footer />
      {/* Registration Wall */}
      <RegistrationWall
        open={showRegistrationWall}
        onClose={() => setShowRegistrationWall(false)}
      />
    </TemplateFrame>
  );
};

export default LandingPage;
