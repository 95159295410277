import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <div className="App">
    <App />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// q: what are reportWebVitals?
// a: reportWebVitals is a function that logs the performance metrics of your app to the console.
// You can use this function to measure the performance of your app and identify areas that need improvement.
// The function logs the following metrics:
// - Largest Contentful Paint (LCP): The time it takes for the largest content element on the page to load.
// - First Input Delay (FID): The time it takes for the page to respond to the first user interaction.
// - Cumulative Layout Shift (CLS): The amount of unexpected layout shifts that occur on the page.
// You can use these metrics to optimize your app's performance and improve the user experience.
// The reportWebVitals function is called in the ReactDOM.render method to log the performance metrics of the app.