import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  Container,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import {
  X,
  ShoppingCart,
  Stethoscope,
  Cat,
  Sparkles,
  Home,
  Laptop,
  Gamepad,
  Book,
  Baby,
  Car,
  Briefcase,
  Trophy,
} from "lucide-react";
import ReactMarkdown from "react-markdown";
import { categoryResponses } from "../../data/categoryResponses";
import ProductSwiper from "./ProductSwiper";

const CategoryCard = ({ icon: Icon, title, onClick }) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Card
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 3,
        cursor: "pointer",
        height: "100%",
        minHeight: "160px",
        transition: "all 0.3s ease",
        backgroundColor: isHovered
          ? theme.palette.background.highlight
          : theme.palette.background.paper,
        transform: isHovered ? "translateY(-4px)" : "none",
        boxShadow: isHovered
          ? `0 8px 16px ${theme.palette.primary.main}20`
          : `0 4px 8px ${theme.palette.primary.main}10`,
        "&:hover": {
          backgroundColor: theme.palette.background.highlight,
        },
      }}
    >
      <Box
        sx={{
          mb: 2,
          p: 2,
          borderRadius: "50%",
          backgroundColor: `${theme.palette.primary.main}10`,
          color: theme.palette.primary.main,
          transition: "all 0.3s ease",
          transform: isHovered ? "scale(1.1)" : "scale(1)",
        }}
      >
        <Icon size={32} />
      </Box>
      <Typography
        variant="subtitle1"
        align="center"
        sx={{
          fontWeight: 600,
          color: theme.palette.text.primary,
        }}
      >
        {title}
      </Typography>
    </Card>
  );
};

const ResponseDialog = ({ open, handleClose, response, title }) => {
  const theme = useTheme();

  const renderMarkdownWithProducts = (markdown) => {
    if (!markdown) return null;

    const lines = markdown.split("\n");
    return lines.map((line, index) => {
      const regex = /{{(.*?)}}/g;
      const matches = line.match(regex);

      if (matches) {
        const productName = matches[0].replace(/[{}]/g, "");
        const reformattedLine = line.replace(regex, `**${productName}**`);
        const amazonLink = `https://www.amazon.com/s?k=${encodeURIComponent(
          productName
        )}&linkCode=ll2&tag=buywithai02c-20&linkId=36a87ed72089253db55f124bbb0db1fb&language=en_US&ref_=as_li_ss_tl`;

        return (
          <React.Fragment key={index}>
            <ReactMarkdown>{reformattedLine}</ReactMarkdown>
            <Box
              component="a"
              href={amazonLink}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "block",
                textDecoration: "none",
                my: 2,
              }}
            >
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 2,
                  backgroundColor: theme.palette.background.paper,
                  "&:hover": {
                    transform: "translateY(-2px)",
                    boxShadow: `0 4px 12px ${theme.palette.primary.main}20`,
                  },
                  transition: "all 0.2s ease-in-out",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: 500,
                  }}
                >
                  Find "{productName}" on Amazon
                </Typography>
              </Card>
            </Box>
          </React.Fragment>
        );
      }

      return <ReactMarkdown key={index}>{line}</ReactMarkdown>;
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "16px",
          p: 2,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        <IconButton onClick={handleClose} size="small">
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography component="div">
          {renderMarkdownWithProducts(response)}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

const CategorySection = ({ setInputValue, scrollToResults }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedCategory, setSelectedCategory] = useState(null);

  const categories = [
    { key: "groceries", icon: ShoppingCart, title: "Groceries" },
    { key: "medical", icon: Stethoscope, title: "Medical Care & Pharmacy" },
    { key: "pets", icon: Cat, title: "Pets" },
    { key: "fashion", icon: Sparkles, title: "Fashion & Beauty" },
    { key: "home", icon: Home, title: "Home & DIY" },
    { key: "electronics", icon: Laptop, title: "Electronics" },
    { key: "gaming", icon: Gamepad, title: "Music, Video & Gaming" },
    { key: "books", icon: Book, title: "Books" },
    { key: "toys", icon: Baby, title: "Toys, Kids & Baby" },
    { key: "automotive", icon: Car, title: "Automotive" },
    { key: "office", icon: Briefcase, title: "Office & Professional" },
    { key: "sports", icon: Trophy, title: "Sports & Outdoor" },
  ];

  const categoryKeyMap = {
    Groceries: "groceries",
    "Medical Care & Pharmacy": "medical",
    Pets: "pets",
    "Fashion & Beauty": "fashion",
    "Home & DIY": "home",
    Electronics: "electronics",
    "Music, Video & Gaming": "gaming",
    Books: "books",
    "Toys, Kids & Baby": "toys",
    Automotive: "automotive",
    "Office & Professional": "office",
    "Sports & Outdoor": "sports",
  };

  // Update the handleCategoryClick function
  const handleCategoryClick = (title) => {
    const categoryKey = categoryKeyMap[title];
    setSelectedCategory(categoryKey);
  };

  const handleCloseDialog = () => {
    setSelectedCategory(null);
  };

  return (
    <Box
      sx={{
        py: { xs: 4, md: 8 },
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{
            mb: 4,
            color: theme.palette.primary.main,
            fontSize: { xs: "2rem", md: "2.5rem" },
            fontWeight: "bold",
          }}
        >
          Explore Categories
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
            },
            gap: 3,
          }}
        >
          {categories.map((category) => (
            <CategoryCard
              key={categoryKeyMap[category.title]}
              icon={category.icon}
              title={category.title}
              onClick={() => handleCategoryClick(category.title)}
            />
          ))}
        </Box>
        <ResponseDialog
          open={Boolean(selectedCategory)}
          handleClose={handleCloseDialog}
          response={selectedCategory ? categoryResponses[selectedCategory].response : ''}
          title={selectedCategory ? categories.find(c => c.key === selectedCategory)?.title : ''}
          setInputValue={setInputValue}
          scrollToResults={scrollToResults}
        />
      </Container>
    </Box>
  );
};

export default CategorySection;
