import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Box,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Lock, MessageSquare, History, BookmarkPlus } from 'lucide-react';

const RegistrationWall = ({ open, onClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Dialog 
      open={open} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 4,
          backgroundImage: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
        }
      }}
    >
      <DialogContent sx={{ p: 4 }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Lock size={48} color={theme.palette.primary.main} />
        </Box>
        
        <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom>
          Ready to unlock more?
        </Typography>
        
        <Typography 
          variant="subtitle1" 
          color="text.secondary" 
          textAlign="center"
          sx={{ mb: 4 }}
        >
          You've reached the limit for free queries. Sign up now to unlock unlimited access and more features!
        </Typography>

        <Box 
          sx={{ 
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
            gap: 3,
            mb: 4,
          }}
        >
          <FeatureCard
            icon={<MessageSquare size={24} />}
            title="Unlimited Queries"
            description="Get unlimited AI-powered shopping recommendations"
          />
          <FeatureCard
            icon={<History size={24} />}
            title="Search History"
            description="Access your past searches and recommendations"
          />
          <FeatureCard
            icon={<BookmarkPlus size={24} />}
            title="Save Products"
            description="Bookmark products and create wishlists"
          />
          <FeatureCard
            icon={<Lock size={24} />}
            title="Premium Features"
            description="Access exclusive tools and features"
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate('/signup')}
            sx={{ 
              py: 1.5,
              fontSize: '1.1rem',
            }}
          >
            Create Free Account
          </Button>
          <Button
            variant="text"
            onClick={() => navigate('/signin')}
          >
            Already have an account? Sign in
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  const theme = useTheme();
  
  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 2,
        bgcolor: 'background.paper',
        boxShadow: `0 4px 12px ${theme.palette.primary.main}10`,
        display: 'flex',
        gap: 2,
        alignItems: 'flex-start',
      }}
    >
      <Box sx={{ color: 'primary.main' }}>
        {icon}
      </Box>
      <Box>
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default RegistrationWall;