import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signIn, confirmSignUp, resendSignUpCode } from "aws-amplify/auth";
import {
  Box,
  Button,
  Container,
  Divider,
  Link,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Alert,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { Mail, Lock, Eye, EyeOff, ArrowLeft } from "lucide-react";
import ForgotPassword from "./ForgotPassword";

const SignIn = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [confirmationError, setConfirmationError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear errors when user types
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email";
    }
    if (!formData.password) {
      newErrors.password = "Password is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    setErrorMessage("");

    try {
      const { isSignedIn, nextStep } = await signIn({
        username: formData.email,
        password: formData.password,
      });

      if (isSignedIn) {
        navigate("/main");
      } else if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
        setShowConfirmation(true);
      }
    } catch (error) {
      setErrorMessage(
        error.message || "An error occurred during sign in. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmation = async () => {
    if (!confirmationCode) {
      setConfirmationError("Please enter the confirmation code");
      return;
    }

    setIsLoading(true);
    setConfirmationError("");

    try {
      await confirmSignUp({
        username: formData.email,
        confirmationCode,
      });

      // After successful confirmation, try to sign in again
      const { isSignedIn } = await signIn({
        username: formData.email,
        password: formData.password,
      });

      if (isSignedIn) {
        navigate("/main");
      }
    } catch (error) {
      setConfirmationError(
        error.message || "Invalid confirmation code. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };
  const ConfirmationDialog = () => (
    <Dialog
      open={showConfirmation}
      onClose={() => setShowConfirmation(false)}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 3,
          p: 1,
        },
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Typography variant="h5" fontWeight="bold">
          Confirm Your Email
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>
          It looks like your email address hasn't been verified yet. Please
          enter the confirmation code we sent to {formData.email}.
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          label="Confirmation Code"
          value={confirmationCode}
          onChange={(e) => setConfirmationCode(e.target.value)}
          error={!!confirmationError}
          helperText={confirmationError}
          placeholder="Enter 6-digit code"
          sx={{ mb: 2 }}
        />
        <Typography variant="caption" color="text.secondary">
          Didn't receive the code?{" "}
          <Button
            variant="text"
            size="small"
            onClick={async () => {
              try {
                await resendSignUpCode({
                  username: formData.email,
                });
                // Show success message
                setConfirmationError("New code sent successfully!");
              } catch (error) {
                console.log(error);
                setConfirmationError(
                  "Failed to send new code. Please try again."
                );
              }
            }}
          >
            Send again
          </Button>
        </Typography>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button onClick={() => setShowConfirmation(false)} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirmation}
          disabled={isLoading}
        >
          {isLoading ? "Verifying..." : "Verify Email"}
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.default",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => navigate("/")}
            sx={{
              color: "primary.main",
              "&:hover": {
                bgcolor: "primary.main",
                color: "white",
              },
            }}
          >
            <ArrowLeft />
          </IconButton>
        </Box>

        <Container
          maxWidth="sm"
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            py: 8,
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              borderRadius: 4,
              p: { xs: 3, sm: 6 },
              boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                mb: 1,
                fontWeight: 700,
              }}
            >
              Welcome back
            </Typography>
            <Typography color="text.secondary" sx={{ mb: 4 }}>
              Sign in to continue to ShopFlair
            </Typography>

            {errorMessage && (
              <Alert
                severity="error"
                sx={{ mb: 3 }}
                onClose={() => setErrorMessage("")}
              >
                {errorMessage}
              </Alert>
            )}

            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                <TextField
                  name="email"
                  label="Email address"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Mail size={20} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />

                <TextField
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock size={20} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <EyeOff size={20} />
                          ) : (
                            <Eye size={20} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />

                <Link
                  component="button"
                  onClick={() => setShowForgotPassword(true)}
                  variant="body2"
                >
                  Forgot password?
                </Link>

                <ForgotPassword
                  open={showForgotPassword}
                  handleClose={() => setShowForgotPassword(false)}
                />

                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={isLoading}
                  sx={{
                    py: 1.5,
                    bgcolor: "primary.main",
                    "&:hover": {
                      bgcolor: "primary.dark",
                    },
                  }}
                >
                  {isLoading ? "Signing in..." : "Sign in"}
                </Button>
              </Box>
            </form>

            <Box sx={{ mt: 4 }}>
              <Divider sx={{ mb: 4 }}>
                <Typography
                  variant="body2"
                  sx={{
                    px: 2,
                    color: "text.secondary",
                  }}
                >
                  or
                </Typography>
              </Divider>

              <Button
                fullWidth
                variant="outlined"
                size="large"
                onClick={() => alert("Google sign-in")}
                sx={{
                  py: 1.5,
                  color: "text.primary",
                  borderColor: "divider",
                  "&:hover": {
                    borderColor: "primary.main",
                    bgcolor: "background.default",
                  },
                }}
              >
                Continue with Google
              </Button>
            </Box>

            <Typography variant="body2" align="center" sx={{ mt: 4 }}>
              Don't have an account?{" "}
              <Link
                href="/signup"
                sx={{
                  color: "primary.main",
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Sign up
              </Link>
            </Typography>
          </Box>
        </Container>

        <ForgotPassword open={open} handleClose={() => setOpen(false)} />
      </Box>
      <ConfirmationDialog />
    </>
  );
};

export default SignIn;
