import React from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SitemarkIconFull from "../assets/logos/SitemarkIconFull";
import PromotionalBanner from "./PromotionalBanner";

function TemplateFrame({ children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <PromotionalBanner sx={{ height: isMobile ? "26px" : "32px" }} />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between", py: { xs: 1, sm: 2 } }}>
          <SitemarkIconFull
            sx={{ height: isMobile ? "20px" : "24px" }}
            logoColor={theme.palette.primary.main}
            textColor={theme.palette.primary.main}
            extraColor={theme.palette.secondary.light}
          />
          <Box sx={{ display: "flex", gap: 1, flex: "center" }}>
            <Button
              color="primary"
              variant="text"
              size="small" // Most frameworks don't have "extrasmall"
              sx={{
                padding: { xs: "4px 8px", sm: "6px 16px" }, // Smaller padding on mobile
                fontSize: { xs: "0.75rem", sm: "0.875rem" }, // Smaller font on mobile
              }}
              href="/signin"
            >
              Sign in
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="small" // Most frameworks don't have "extrasmall"
              sx={{
                padding: { xs: "4px 8px", sm: "6px 16px" }, // Smaller padding on mobile
                fontSize: { xs: "0.75rem", sm: "0.875rem" }, // Smaller font on mobile
              }}
              href="/signup"
            >
              Sign up
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ flexGrow: 1, overflow: "auto" }}>
        {children}
      </Box>
    </Box>
  );
}

TemplateFrame.propTypes = {
  children: PropTypes.node,
};

export default TemplateFrame;
