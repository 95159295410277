// ForgotPassword.js
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  Alert,
  Stepper,
  Step,
  StepLabel,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Mail, ArrowRight, Check, X, Eye, EyeOff, Lock, RotateCcw } from 'lucide-react';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';

const steps = ['Email Verification', 'Reset Password'];

const ForgotPassword = ({ open, handleClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleReset = async () => {
    if (!email) {
      setError('Please enter your email address');
      return;
    }

    setLoading(true);
    setError('');

    try {
      await resetPassword({ username: email });
      setActiveStep(1);
    } catch (err) {
      setError(err.message || 'Failed to send reset code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmReset = async () => {
    if (!code || !newPassword) {
      setError('Please fill in all fields');
      return;
    }

    setLoading(true);
    setError('');

    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword,
      });
      setSuccess(true);
      setTimeout(() => {
        handleClose();
        // Reset state
        setActiveStep(0);
        setEmail('');
        setCode('');
        setNewPassword('');
        setError('');
        setSuccess(false);
      }, 2000);
    } catch (err) {
      setError(err.message || 'Failed to reset password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setActiveStep(0);
    setError('');
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 3,
          maxWidth: 480,
        }
      }}
    >
      <DialogTitle>
        <Typography variant="h5" fontWeight="bold" sx={{ mb: 1 }}>
          Reset your password
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>

      <DialogContent>
        {error && (
          <Alert 
            severity="error" 
            sx={{ mb: 3 }}
            action={
              <IconButton
                color="inherit"
                size="small"
                onClick={() => setError('')}
              >
                <X size={16} />
              </IconButton>
            }
          >
            {error}
          </Alert>
        )}

        {success ? (
          <Alert 
            severity="success"
            icon={<Check />}
            sx={{ mb: 2 }}
          >
            Password successfully reset! You can now sign in with your new password.
          </Alert>
        ) : activeStep === 0 ? (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Enter your email address and we'll send you instructions to reset your password.
            </Typography>
            <TextField
              autoFocus
              fullWidth
              label="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail size={20} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        ) : (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Enter the verification code sent to {email} and your new password.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <TextField
                autoFocus
                fullWidth
                label="Verification Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Enter 6-digit code"
              />
              <TextField
                fullWidth
                label="New Password"
                type={showPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock size={20} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
          {activeStep === 1 && (
            <Button
              onClick={handleBack}
              disabled={loading}
              startIcon={<RotateCcw size={16} />}
              sx={{ flex: 1 }}
            >
              Back
            </Button>
          )}
          <Button
            variant="contained"
            onClick={activeStep === 0 ? handleReset : handleConfirmReset}
            disabled={loading || success}
            endIcon={<ArrowRight size={16} />}
            sx={{ flex: 1 }}
          >
            {loading ? 'Processing...' : activeStep === 0 ? 'Send Reset Link' : 'Reset Password'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ForgotPassword;